<template>
<div>
    <router-view/>
</div>
</template>

<script>
export default {
  name: 'Tasklists',
  metaInfo: {
    title: 'Tasklists'
  }
}
</script>

<style>

</style>
